// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const Constants = {
  logo: 'Ingro-logo.png',
  /* host : 'https://ingrob2b.aziendapro.it/admin/sw/function.php', */
  host : 'https://b2b.ingromarketsrl.it/admin/sw/function.php',
  nomeAzienda: "INGROMARKET S.r.l.",
  nomeBreve: "Ingromarket",
  sitoweb: "https://b2b.ingromarketsrl.it/",
  privacyEmail: "privacy@ingromarkertsrl.it",
  nummeroWhatsapp: '393510804200',
  
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
