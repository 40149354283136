import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralServiceService } from './services/general-service.service';
import { LocalStorageService } from './services/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ingrob2b';

  constructor(private router: Router,
    private local: LocalStorageService, private genericFunc: GeneralServiceService) {}

    ngOnInit() {
      console.log(this.local.getLocal('username'));
      
    }

    onActivate(event) {
      window.scroll(0,0);
  }
}
