import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setLocal(item, value) {
    return localStorage.setItem(item, value);

  }
  getLocal(item) {
    return localStorage.getItem(item)
  }

  delete(item) {
  return localStorage.removeItem(item);
  }
}
