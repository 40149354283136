import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/environments/environment';
import { UserModel } from '../models/dto/user.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralServiceService {
   _currentUser: UserModel;
  loggato: boolean = false;
  totCart: any = 0;
  idCart: any;
  subjectLoggato = new Subject();
  subjectUser = new Subject();
  subjectCart = new Subject();
  subjectCurrentPage = new Subject();
  _messaggioEsitoOrdine: string = "";
  minimoOrd: any = 0;
  estero: any = 'NO';
  private _loggatoHeader: boolean = false;
  private _userHeader: UserModel;
  private _itemCartHeader: any = 0;
  private _currentToken: string;
  private _currentPage: any = 0;

  constructor(private httpClient: HttpClient) { 

    this._currentUser = new UserModel(null,null,null,null,null,null,null);
  }


  public genericFunction(dati: any) {
    //this._currentUser = new UserModel(null,null,null,null,null, null);
   
    return this.httpClient.post<any>(Constants.host, dati);
  }

  changeLoggato(loggatoNew: boolean) {
    this._loggatoHeader = loggatoNew;

    this.subjectLoggato.next(this._loggatoHeader);
  }

  changeCurrentUser(userNew: UserModel) {
    this._userHeader = userNew;

    this.subjectUser.next(this._userHeader);
  }

  changeItemCart(totCartNew: any) {
    this._itemCartHeader = totCartNew;

    this.subjectCart.next(this._itemCartHeader);
  }

  changeCurrentPage(codePage: any) {
    this._currentPage = codePage;

    this.subjectCurrentPage.next(this._currentPage);
  }

  /**
   * Getter currentUser
   * @return {UserModel}
   */
	public get currentUser(): UserModel {
		return this._currentUser;
	}

  

  /**
   * Setter device
   * @param {DeviceModel} value
   */
	/*public set device(value: DeviceModel) {
		this._device = value;
	}*/

  
  /**
   * Setter currentUser
   * @param {UserModel} value
   */
	public set currentUser(value: UserModel) {
		this._currentUser = value;
	}

    /**
     * Getter currentToken
     * @return {string}
     */
	public get currentToken(): string {
		return this._currentToken;
	}

    /**
     * Setter currentToken
     * @param {string} value
     */
	public set currentToken(value: string) {
		this._currentToken = value;
	}


     /**
     * Getter currentToken
     * @return {string}
     */
	public get messaggioEsitoOrdine(): string {
		return this._messaggioEsitoOrdine;
	}

    /**
     * Setter currentToken
     * @param {string} value
     */
	public set messaggioEsitoOrdine(value: string) {
		this._messaggioEsitoOrdine = value;
	}

  

 
  
}
