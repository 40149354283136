export class UserModel {
    private _cellulare: string; 
    private _cognome: string; 
    private _email: string; 
    private _idut: string; 
    private _nome: string; 
    private _password: string; 
    private _rating: string; 
    private _sesso: string; 
    private _user: string; 
    private _societa: string;


    constructor(idut: string, nome:  string,  cognome: string, email: string, cellulare: string, user: string, societa: string) {
           
           this._cellulare= cellulare; 
           this._cognome= cognome; 
           this._email= email; 
           this._idut= idut; 
           this._nome= nome; 
            
           this._user= user; 
           this._societa = societa;
   
       }

       public get nome(): string {
        return this._nome;
        }
        public set nome(value: string) {
                this._nome = value;
        }

        public get cognome(): string {
            return this._cognome;
        }
        public set cognome(value: string) {
                this._cognome = value;
        }

        public get cellulare(): string {
            return this._cellulare;
        }
        public set cellulare(value: string) {
                this._cellulare = value;
        }

        public get email(): string {
            return this._email;
        }
        public set email(value: string) {
                this._email = value;
        }

        public get idut(): string {
            return this._idut;
        }
        public set idut(value: string) {
                this._idut = value;
        }

        public get password(): string {
            return this._password;
        }
        public set password(value: string) {
                this._password = value;
        }

        public get rating(): string {
            return this._rating;
        }
        public set rating(value: string) {
                this._rating = value;
        }

        public get sesso(): string {
            return this._sesso;
        }
        public set sesso(value: string) {
                this._sesso = value;
        }

        public get user(): string {
            return this._user;
        }
        public set user(value: string) {
                this._user = value;
        }

        public get societa(): string {
                return this._societa;
            }
            public set societa(value: string) {
                    this._societa = value;
            }


        
}